<template>
  <f7-page class="influencerview-page" infinite :infinite-distance="50" :infinite-preloader="showPreloader" @infinite="loadMore">
    <template #fixed>
      <ProfileNavigationComponent :title="profileInfo?.IsInfluencer == 1 ? $t.getTranslation('LBL_INFLUENCER') : $t.getTranslation('LBL_USER')" type="back" :settings="false" />
    </template>

    <section class="profile-container">
      <div class="container">
        <div class="profile-info">
          <div class="image">
            <img :src="$h.getImage(profileInfo?.Image, 'USER')" :alt="$t.getTranslation('LBL_PROFILE_PICTURE')" />
          </div>
          <div class="subcontent">
            <div class="counter">
              <div class="count">
                <h3>{{ $h.formatDisplayNumber(profileInfo?.UserCounter?.TotalPost) }}</h3>
                <h4>{{ $t.getTranslation("LBL_POST") }}</h4>
              </div>
              <div class="count">
                <h3>{{ $h.formatDisplayNumber(profileInfo?.UserCounter?.TotalFollow) }}</h3>
                <h4>{{ $t.getTranslation("LBL_FOLLOWS") }}</h4>
              </div>
              <div class="count">
                <h3>{{ $h.formatDisplayNumber(profileInfo?.UserCounter?.TotalFollower) }}</h3>
                <h4>{{ $t.getTranslation("LBL_FOLLOWERS") }}</h4>
              </div>
            </div>

            <f7-button fill small :disabled="!$h.isUserLogin() || $h.checkFollowDisabled(profileInfo?.UserKey)" @click="followToggle(profileInfo?.UserKey)">
              {{ profileInfo?.IsFollow === 1 ? $t.getTranslation("LBL_UNFOLLOW") : $t.getTranslation("LBL_FOLLOW") }}
            </f7-button>
          </div>
        </div>

        <div class="profile-content">
          <div class="name">
            <h3>{{ $h.getFullName(profileInfo) }}</h3>
            <h4>{{ profileInfo?.IsInfluencer == 1 ? $t.getTranslation("LBL_INFLUENCER") : $t.getTranslation("LBL_USER") }}</h4>
          </div>


          <!-- seems redundant -->
          <!-- <p>{{ $h.formatTrimString(profileInfo?.Bio, 130) || $t.getTranslation("LBL_BIO_EMPTY") }}</p> -->

          <ul v-if="profileInfo?.Keywords">
            <li v-for="keyword in profileInfo?.Keywords" :key="'ky_' + keyword">{{ keyword }}</li>
          </ul>
        </div>

        <div v-if="profileInfo && profileInfo.UserStatusCode === 'ACTIVE'" class="profile-tabs">
          <f7-toolbar tabbar class="tablinks">
            <f7-link tab-link="#tabPosts" tab-link-active>{{ $t.getTranslation("LBL_POSTS") }}</f7-link>
            <f7-link tab-link="#tabAbout">{{ $t.getTranslation("LBL_ABOUT") }}</f7-link>
          </f7-toolbar>

          <f7-tabs>
            <f7-tab id="tabPosts" class="page-content profile-posts-tab" tab-active>
              <PostListComponent ref="postListComponent" :userkey="profileInfo.UserKey" :size="12" mode="influencer" />
            </f7-tab>
            <f7-tab id="tabAbout" class="page-content profile-about-tab">
              <p>{{ profileInfo.Bio || $t.getTranslation("LBL_BIO_EMPTY") }}</p>
            </f7-tab>
          </f7-tabs>
        </div>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, onMounted, defineAsyncComponent } from "vue";
import { get, post } from "@/utils/axios";
import { useStore } from "@/store";

// import ProfileNavigationComponent from "@/components/navigations/ProfileNavigationComponent.vue";
// import PostListComponent from "@/components/PostListComponent.vue";

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProfileNavigationComponent.vue"));
const PostListComponent  = defineAsyncComponent(() => import(/* webpackChunkName: "post-list" */ /* webpackMode: "lazy" */ "@/components/PostListComponent.vue"));

export default defineComponent({
  name: "InfluencerViewPage",
  components: {
    ProfileNavigationComponent,
    PostListComponent,
  },
  props: { f7router: Object, f7route: Object },
  setup(props) {

    const store = useStore();

    const profileInfo = ref(false);

    const postListComponent = ref(false);
    const showPreloader = ref(false);

    const getProfileInfo = async () => {
      let ret = await get("/influencer/info", { UserKey: props?.f7route?.params?.code });
      if (ret) {
        profileInfo.value = ret;
      } else {
        props.f7router.back({ force: true });
      }
    };

    onMounted(() => {
      getProfileInfo();
    });

    const followToggle = async (UserKey) => {
      await post("/follow/toggle", { FollowKey: UserKey, Type: "USER" });
      getProfileInfo();
    };

    return { followToggle, profileInfo, postListComponent, showPreloader };
  },
  methods: {
    async loadMore() {
      this.showPreloader = true;
      if (this?.$refs?.postListComponent) await this.$refs.postListComponent.loadMore();
      this.showPreloader = false;
    },
  },
});
</script>

<style scoped>
.profile-container .container {
  padding-bottom: 0px;
}
</style>
